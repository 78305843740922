import * as React from 'react';
import { FormTitle, FieldList, FieldItem, FieldInfo, FormFooter, Checkbox } from './form';
import * as styles from './form.module.scss';
const formConfig = require('./foi-complaint-form.json');

const FOIComplaintFormStep2 = (props) => {
  const {
    formData, 
    formErrors,
    onChange, 
    onSubmit,
    onPrevious,
  } = props;

  return (
    <form
      className={styles.page}
      onSubmit={onSubmit}
      noValidate>
      <FormTitle 
        text={formConfig.steps[1]}
      />
      <FieldList>
        <FieldItem>
          <FieldInfo 
            {...formConfig.beforeYouBegin}
          />
          <Checkbox 
            label="I understand a copy of this complaint and any supporting information and attachments will be provided to the agency or Minister."
            name="notificationConfirmation"
            value={formData.notificationConfirmation}
            error={formErrors.notificationConfirmation}
            required={true}
            onChange={onChange}
          />
        </FieldItem>
      </FieldList>
      <FormFooter 
        next="Next: Complaint details"
        onPrevious={onPrevious}
      />
    </form>
  )
}

export default FOIComplaintFormStep2;
