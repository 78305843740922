import * as React from 'react';
import { ShowIf } from './helpers';
import { Container } from './grid';
import OVICForm from './ovic-form';
import FormHeader from './form-header';
import FormError from './form-error';
import FormLoading from './form-loading';
import FOIComplaintFormStep1 from './foi-complaint-form-step-1';
import FOIComplaintFormStep2 from './foi-complaint-form-step-2';
import FOIComplaintFormStep3 from './foi-complaint-form-step-3';
import FOIComplaintFormStep4 from './foi-complaint-form-step-4';
import FOIComplaintFormStep5 from './foi-complaint-form-step-5';
import FOIComplaintFormStep6 from './foi-complaint-form-step-6';
import FOIComplaintFormStep7 from './foi-complaint-form-step-7';
import * as styles from './form.module.scss';
const formConfig = require('./foi-complaint-form.json');

class FOIComplaintForm extends OVICForm 
{
  constructor(props) {
    super(props);

    this.state = {
      form: 'foicomplaint',
      step: 1,
      steps: 7,
      files: [],
      loading: false,
      percentLoaded: 0,
      formData: {
        notificationConfirmation: '',
        areYouApplyingAgency: '',
        complaintAgency: '',
        complaintAgencyReference: '',
        complaintTimeframe: '',
        complaintDate: '',
        complaintTypeProcessTime: '',
        complaintTypeDocumentDoesNotExist: '',
        complaintTypeDocumentMissing: '',
        complaintTypeProfessionalStandards: '',
        complaintTypeOther: '',
        complaintTypeOtherDescription: '',
        complaintDetails: '',
        complaintResolve: '',
        contactRepresentative: '',
        representativeTitle: '',
        representativeFirstName: '',
        representativeSurname: '',
        representativeOrganisation: '',
        representativeRelationship: '',
        representativeAuthority: '',
        contactTitle: '',
        contactFirstName: '',
        contactSurname: '',
        contactOrganisation: '',
        contactPhone: '',
        contactEmail: '',
        contactAddress: '',
        attachmentRequest: '',
        attachmentDecision: '',
        attachmentCorrespondence: '',
        attachmentOther: '',
        attachmentOtherDescription: '',
        declaration1: '',
        declaration2: '',
      },
      formErrors: {
      },
    }
  }

  render() {
    return (
      <div 
        className={styles.root}
        ref={this.rootRef}>
        <FormHeader
          title={formConfig.title}
          steps={formConfig.steps}
          step={this.state.step}
        />
        <Container>
          <FOIComplaintFormStep1 
            onInput={this.handleInput}
            onChange={this.handleChange}
            onSubmit={this.handleSubmit}
            onPrevious={this.handlePrevious}
            onUpdateFiles={this.handleUpdateFiles}
            formData={this.state.formData}
            formErrors={this.state.showErrors ? this.state.formErrors : false}
          />
          <FOIComplaintFormStep2 
            onInput={this.handleInput}
            onChange={this.handleChange}
            onSubmit={this.handleSubmit}
            onPrevious={this.handlePrevious}
            onUpdateFiles={this.handleUpdateFiles}
            formData={this.state.formData}
            formErrors={this.state.showErrors ? this.state.formErrors : false}
          />
          <FOIComplaintFormStep3 
            onInput={this.handleInput}
            onChange={this.handleChange}
            onSubmit={this.handleSubmit}
            onPrevious={this.handlePrevious}
            onUpdateFiles={this.handleUpdateFiles}
            formData={this.state.formData}
            formErrors={this.state.showErrors ? this.state.formErrors : false}
          />
          <FOIComplaintFormStep4 
            onInput={this.handleInput}
            onChange={this.handleChange}
            onSubmit={this.handleSubmit}
            onPrevious={this.handlePrevious}
            onUpdateFiles={this.handleUpdateFiles}
            formData={this.state.formData}
            formErrors={this.state.showErrors ? this.state.formErrors : false}
          />
          <FOIComplaintFormStep5
            onInput={this.handleInput}
            onChange={this.handleChange}
            onSubmit={this.handleSubmit}
            onPrevious={this.handlePrevious}
            onUpdateFiles={this.handleUpdateFiles}
            formData={this.state.formData}
            formErrors={this.state.showErrors ? this.state.formErrors : false}
          />
          <FOIComplaintFormStep6
            onInput={this.handleInput}
            onChange={this.handleChange}
            onSubmit={this.handleSubmit}
            onPrevious={this.handlePrevious}
            onUpdateFiles={this.handleUpdateFiles}
            formData={this.state.formData}
            formErrors={this.state.showErrors ? this.state.formErrors : false}
          />
          <FOIComplaintFormStep7
            onInput={this.handleInput}
            onChange={this.handleChange}
            onSubmit={this.handleSubmit}
            onPrevious={this.handlePrevious}
            onUpdateFiles={this.handleUpdateFiles}
            formData={this.state.formData}
            formErrors={this.state.showErrors ? this.state.formErrors : false}
          />
        </Container>
        <ShowIf cond={this.state.loading}>
          <FormLoading 
            percent={this.state.percentLoaded}
          />
        </ShowIf>
        <ShowIf cond={this.state.submitError}>
          <FormError>
            <p>This form could no be submitted due to a system error.</p>
          </FormError>
        </ShowIf>
      </div>
    )
  }
}

export default FOIComplaintForm;
