import * as React from 'react';
import { FormTitle, FormConfirmation } from './form';
import * as styles from './form.module.scss';
const formConfig = require('./foi-complaint-form.json');

const FOIComplaintFormStep7 = (props) => {
  return (
    <form
      className={styles.page}>
      <FormTitle 
        text={formConfig.steps[6]}
      />
      <FormConfirmation>
        <p>You will now receive an email with a copy of this complaint for your own records.</p>
        <p>To find out what you can expect next from our FOI complaints process, please see our guidance on <a href="https://ovic.vic.gov.au/freedom-of-information/make-a-complaint/" target="_blank" rel="noopener noreferrer">how to make an FOI complaint</a>, or contact us on 1300 00 6842 or by email at enquiries@ovic.vic.gov.au</p>
      </FormConfirmation>
    </form>
  )
}

export default FOIComplaintFormStep7;
