import * as React from 'react';
import { ShowIf } from './helpers';
import { FormTitle, FieldList, FieldItem, FieldInfo, CheckboxGroup, FormFooter, TextField, NoYesGroup, TextArea } from './form';
import * as styles from './form.module.scss';
const globalConfig = require('./ovic-form.json');
const formConfig = require('./foi-complaint-form.json');

const FOIComplaintFormStep3 = (props) => {
  const {
    formData, 
    formErrors,
    onChange, 
    onSubmit,
    onPrevious,
  } = props;

  return (
    <form
      className={styles.page}
      onSubmit={onSubmit}
      noValidate>

      <FormTitle 
        text={formConfig.steps[2]}
      />

      <FieldList>
        <FieldItem>
          <FieldInfo 
            {...formConfig.areYouApplyingAgency}
          />
          <NoYesGroup 
            name="areYouApplyingAgency"
            value={formData.areYouApplyingAgency}
            error={formErrors.areYouApplyingAgency}
            required={true}
            onChange={onChange}
          />
        </FieldItem>
      </FieldList>

      <ShowIf cond={formData.areYouApplyingAgency === 'No'}>
        <FieldInfo>
          <p>Unfortunately we are unable to assist you. <a href="https://ovic.vic.gov.au/freedom-of-information/for-the-public/foi-complaints/#who-else-might-be-able-to-help-me" target="_blank" rel="noopener noreferrer">Find information on other organisations  that may be able to assist you.</a></p><br/><br/>
        </FieldInfo>
      </ShowIf>

      <ShowIf cond={formData.areYouApplyingAgency === 'Yes'}>
        <FieldList>
          <FieldItem>
            <FieldInfo 
              {...formConfig.complaintAgency}
            />
            <TextField 
              type="text"
              label="Agency or Minister’s name"
              name="complaintAgency"
              value={formData.complaintAgency}
              error={formErrors.complaintAgency}
              required={true}
              onChange={onChange}
              characterCount={true}
              attrs={{
                maxLength: globalConfig.charLimits.short,
              }}
            />
          </FieldItem>
          <FieldItem>
            <FieldInfo 
              {...formConfig.complaintAgencyReference}
            />
            <TextField 
              type="text"
              label="Their reference number:"
              name="complaintAgencyReference"
              value={formData.complaintAgencyReference}
              error={formErrors.complaintAgencyReference}
              onChange={onChange}
              characterCount={true}
              attrs={{
                maxLength: globalConfig.charLimits.short,
              }}
            />
          </FieldItem>
          <FieldItem>
            <FieldInfo 
              {...formConfig.complaintTimeframe}
            />
            <NoYesGroup 
              name="complaintTimeframe"
              value={formData.complaintTimeframe}
              error={formErrors.complaintTimeframe}
              required={true}
              onChange={onChange}
            />
          </FieldItem>
        </FieldList>

        <ShowIf cond={formData.complaintTimeframe === 'No'}>
          <FieldInfo>
            <p>We cannot accept your complaint unless an act or omission of the agency or Minister stopped you from making this complaint to us within 60 days. <a href="https://ovic.vic.gov.au/about-us/contact-us/" target="_blank" rel="noopener noreferrer">Contact us</a> to discuss if you can make a complaint or not.</p>
          </FieldInfo>
        </ShowIf>

        <ShowIf cond={formData.complaintTimeframe === 'Yes'}>
          <FieldList>
            <FieldItem>
              <TextField 
                type="text"
                label="What date did the act or conduct occur?"
                name="complaintDate"
                value={formData.complaintDate}
                error={formErrors.complaintDate}
                required={true}
                onChange={onChange}
                characterCount={true}
                attrs={{
                  maxLength: globalConfig.charLimits.short,
                }}
              />
            </FieldItem>
            <FieldItem>
              <FieldInfo 
                {...formConfig.complaintType}
              />
              <CheckboxGroup 
                name="complaintType"
                options={formConfig.complaintType.options}
                values={formData}
                error={formErrors.complaintType}
                onChange={onChange}
                required={true}
              />
              <ShowIf cond={formData.complaintTypeOther}>
                <TextField 
                  type="text"
                  label="Other complaint details"
                  name="complaintTypeOtherDescription"
                  value={formData.complaintTypeOtherDescription}
                  error={formErrors.complaintTypeOtherDescription}
                  onChange={onChange}
                  characterCount={true}
                  attrs={{
                    maxLength: globalConfig.charLimits.short,
                  }}
                />
              </ShowIf>
            </FieldItem>
            <FieldItem>
              <FieldInfo 
                {...formConfig.complaintDetails}
              />
              <TextArea 
                label=""
                name="complaintDetails"
                value={formData.complaintDetails}
                error={formErrors.complaintDetails}
                required={true}
                onChange={onChange}
                characterCount={true}
                attrs={{
                  maxLength: globalConfig.charLimits.long,
                }}
              />
            </FieldItem>
            <FieldItem>
              <FieldInfo 
                {...formConfig.complaintResolve}
              />
              <TextArea 
                label=""
                name="complaintResolve"
                value={formData.complaintResolve}
                error={formErrors.complaintResolve}
                onChange={onChange}
                characterCount={true}
                attrs={{
                  maxLength: globalConfig.charLimits.medium,
                }}
              />
            </FieldItem>
          </FieldList>

          <FormFooter 
            next="Next: Contact details"
            previous="Previous: Before you begin"
            onPrevious={onPrevious}
          />
        </ShowIf>
      </ShowIf>
    </form>
  )
}

export default FOIComplaintFormStep3;
