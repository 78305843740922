import * as React from 'react';
import { Row, Cell } from './grid';
import { FormTitle, Button } from './form';
import FormIntro from './form-intro';
import * as styles from './form.module.scss';

const FOIComplaintFormStep1 = (props) => {
  const {
    onSubmit,
  } = props;

  return (
    <form
      className={styles.page}
      onSubmit={onSubmit}
      noValidate>
      <FormIntro>
        <FormTitle 
          text="Freedom of information complaint form"
        />
        <Row>
          <Cell className="large-7">
            <h2>What you should know about using this form</h2>
            <p>Use this form to make a freedom of information (<strong>FOI</strong>) complaint to OVIC about a Victorian public sector agency or Minister.</p>
            <p>Before making a complaint to us it is important to read about <a href="https://ovic.vic.gov.au/freedom-of-information/for-the-public/foi-complaints/" target="_blank" rel="noopener noreferrer">what you can complain about, our FOI complaints process, and what happens next.</a></p>
            <h3>How will the information I provide be used?</h3>
            <p>We are required to provide a copy of this complaint to the agency or Minister you are complaining about. We use the information you provide to help us to resolve your complaint. This includes contacting the agency or Minister to notify them of your complaint, discussing the complaint with them, and collecting information about you from them. We may also discuss your complaint with third parties if their rights or interests are affected.</p>
            <p>Information you enter onto this form is stored locally in your browser until you submit it. If you do not submit the form, the information you enter will stay stored locally in your browser until you clear the browser cache or delete any information you entered onto the form.</p>
            <p>You may also clear the form and start again by using the "reset this form" button.</p>
            <p>We manage your personal information in accordance with our <a href="https://ovic.vic.gov.au/about-us/internal-policies-procedures-and-registers/privacy-policy/" target="_blank" rel="noopener noreferrer">Privacy Policy.</a></p>
            <Button 
              text="Start your complaint"
            />
          </Cell>
          <Cell className="large-5">
            <Button 
              text="Start your complaint"
              className="show-for-large"
            />
            <p><em>This form will take 15 - 30 minutes to complete.</em></p>
            <p><em>You will be emailed a copy of your submission.</em></p>
            <h2>Need help?</h2>
            <p>Contact us by phone on <strong>1300 00 6842</strong> or email at enquiries@ovic.vic.gov.au.</p>
            <h2>More information?</h2>
            <p>You can find more information about OVIC's <a href="https://ovic.vic.gov.au/freedom-of-information/make-a-complaint/" target="_blank" rel="noopener noreferrer">FOI complaint process on our website.</a></p>
            <Button 
              text="Start your complaint"
              className="hide-for-large"
            />
          </Cell>
        </Row>
      </FormIntro>
    </form>
  )
}

export default FOIComplaintFormStep1;
