import * as React from 'react';
import { ShowIf } from './helpers';
import { FormTitle, FieldList, FieldItem, FieldInfo, FieldMessage, FieldSet, FieldError, FormFooter, TextField, SelectMenu, NoYesGroup, Checkbox } from './form';
import * as styles from './form.module.scss';
const globalConfig = require('./ovic-form.json');
const formConfig = require('./foi-complaint-form.json');

const FOIComplaintFormStep4 = (props) => {
  const {
    formData, 
    formErrors,
    onInput,
    onChange, 
    onSubmit,
    onPrevious,
  } = props;

  return (
    <form
      className={styles.page}
      onSubmit={onSubmit}
      noValidate>
      <FormTitle 
        text={formConfig.steps[3]}
      />
      
      <FieldList>
        <FieldItem>
          <FieldInfo 
            {...formConfig.contactRepresentative}
          />
          <NoYesGroup 
            name="contactRepresentative"
            value={formData.contactRepresentative}
            error={formErrors.contactRepresentative}
            required={true}
            onChange={onChange}
          />
        </FieldItem>
        <ShowIf cond={formData.contactRepresentative === 'Yes'}>
          <FieldItem>
            <FieldInfo 
              {...formConfig.contactRepresent}
            />
            <SelectMenu
              label="Title"
              name="representativeTitle"
              value={formData.representativeTitle}
              error={formErrors.representativeTitle}
              onChange={onChange}
              options={globalConfig.contactTitle}
            />
            <TextField 
              type="text"
              label="First Name"
              name="representativeFirstName"
              value={formData.representativeFirstName}
              error={formErrors.representativeFirstName}
              required={true}
              onChange={onChange}
              characterCount={true}
              attrs={{
                maxLength: globalConfig.charLimits.xshort,
              }}
            />
            <TextField 
              type="text"
              label="Last Name"
              name="representativeSurname"
              value={formData.representativeSurname}
              error={formErrors.representativeSurname}
              required={true}
              onChange={onChange}
              characterCount={true}
              attrs={{
                maxLength: globalConfig.charLimits.xshort,
              }}
            />
            <TextField 
              type="text"
              label="Organisation (if applicable)"
              name="representativeOrganisation"
              value={formData.representativeOrganisation}
              error={formErrors.representativeOrganisation}
              onChange={onChange}
              characterCount={true}
              attrs={{
                maxLength: globalConfig.charLimits.xshort,
              }}
            />
            <TextField 
              type="text"
              label="Your relationship"
              name="representativeRelationship"
              value={formData.representativeRelationship}
              error={formErrors.representativeRelationship}
              onChange={onChange}
              characterCount={true}
              attrs={{
                maxLength: globalConfig.charLimits.xshort,
              }}
            />
          </FieldItem>
          <FieldItem>
            <FieldInfo
              {...formConfig.representativeAuthority}
            />
            <Checkbox 
              label="I confirm I have authority to act for the person named above. I have their consent to receive and disclose their information and authority to resolve, finalise, or close this complaint."
              name="representativeAuthority"
              value={formData.representativeAuthority}
              error={formErrors.representativeAuthority}
              required={true}
              onChange={onChange}
            />
          </FieldItem>
        </ShowIf>
      </FieldList>

      <FieldList>
        <FieldItem>
          <FieldInfo
            {...formConfig.contactName}
          />
          <SelectMenu
            label="Title"
            name="contactTitle"
            value={formData.contactTitle}
            error={formErrors.contactTitle}
            onChange={onChange}
            options={globalConfig.contactTitle}
          />
          <TextField 
            type="text"
            label="First Name"
            name="contactFirstName"
            value={formData.contactFirstName}
            error={formErrors.contactFirstName}
            required={true}
            onChange={onChange}
            characterCount={true}
            attrs={{
              maxLength: globalConfig.charLimits.xshort,
            }}
          />
          <TextField 
            type="text"
            label="Last Name"
            name="contactSurname"
            value={formData.contactSurname}
            error={formErrors.contactSurname}
            required={true}
            onChange={onChange}
            characterCount={true}
            attrs={{
              maxLength: globalConfig.charLimits.xshort,
            }}
          />
          <TextField 
            type="text"
            label="Organisation (if applicable)"
            name="contactOrganisation"
            value={formData.contactOrganisation}
            error={formErrors.contactOrganisation}
            onChange={onChange}
            characterCount={true}
            attrs={{
              maxLength: globalConfig.charLimits.xshort,
            }}
          />
        </FieldItem>
      </FieldList>
      
      <FieldList>
        <FieldItem>
          <FieldInfo
            {...formConfig.contactMethod}
          />
          <FieldSet>
            <TextField 
              type="tel"
              label="Phone (if you are using a landline, please include your area code)"
              name="contactPhone"
              value={formData.contactPhone}
              error={formErrors.contactPhone}
              onInput={onInput}
              onChange={onChange}
              required={true}
              hideErrors={true}
              attrs={{
                input: '[0-9]',
                pattern: '[0-9]{10}',
                maxLength: 10,
              }}
            />
            <TextField 
              type="email"
              label="Email"
              name="contactEmail"
              value={formData.contactEmail}
              error={formErrors.contactEmail}
              required={!formData.contactAddress}
              hideErrors={true}
              onChange={onChange}>
              {!formErrors && (
                <FieldMessage
                  sidebar={true}
                  group={true}
                  className="show-for-large">
                  Please provide a phone number and <br/>either an email or postal address.
                </FieldMessage>
              )}
              {formErrors && (!formData.contactPhone || (!formData.contactEmail || !formData.contactAddress)) && (
                <FieldError 
                  group={true}
                  name="contactEmail"
                  className="show-for-large"
                  error="Please provide a phone number and <br/>either an email or postal address."
                />
              )}
            </TextField>
            <TextField 
              type="text"
              label="Post"
              name="contactAddress"
              value={formData.contactAddress}
              error={formErrors.contactAddress}
              required={!formData.contactEmail}
              hideErrors={true}
              onChange={onChange}
            />
            <input 
              type="text" 
              name="contactDetails"
              defaultValue={formData.contactEmail + formData.contactAddress} 
              required={true}
              style={{display: 'none'}}
            />
            {formErrors && !formData.contactPhone && !formData.contactEmail && !formData.contactAddress && (
              <FieldError 
                name="contactPhone"
                className="hide-for-large"
                error="Please provide a phone number and either an email or postal address."
              />
            )}
          </FieldSet>
        </FieldItem>
      </FieldList>

      <FormFooter 
        next="Next: Supporting documents and attachments"
        onPrevious={onPrevious}
      />
    </form>
  )
}

export default FOIComplaintFormStep4;
